import React, { useState, useEffect, useContext } from "react";
import axios from "axios";

export const DataContext = React.createContext();
export const useData = () => useContext(DataContext);
export const DataProvider = ({ children, ...initOptions }) => {
    const [data, setData] = useState();

    useEffect(() => {
        axios
            .get("https://go.12-05.de/wp-json/1205checkin/v1/data")
            .then((res) => {
                setData(res.data);
                console.log(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    const exports = { data };

    return (
        <DataContext.Provider value={exports}>{children}</DataContext.Provider>
    );
};
