import "./style.scss";
import Form from "./Form";
import Auschecken from "./Auschecken";

import logo from "./logo.svg";
import { BrowserRouter, Route } from "react-router-dom";

function App() {
    return (
        <BrowserRouter>
            <div className='App'>
                <div id='Layout'>
                    <img alt='Logo' src={logo} />
                    <Route
                        path='/checkout/:token'
                        exact
                        component={Auschecken}
                    />
                    <Route path='/' exact component={Form} />
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
