import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import login from "./login.svg";

export default function Auschecken() {
    const { token } = useParams();
    const [checkedOut, setCheckedOut] = useState(false);
    const checkOut = () => {
        axios
            .get("https://go.12-05.de/wp-json/1205checkin/v1/checkout/" + token)
            .then((res) => {
                setCheckedOut(true);
            })
            .catch((error) => {
                alert("Es ist ein Fehler aufgetreten.");
            });
    };
    if (checkedOut) {
        return (
            <div id='Auschecken'>
                <div className='text'>Sie wurden erfolgreich ausgecheckt.</div>
            </div>
        );
    }
    return (
        <div id='Auschecken'>
            <div className='text'>
                Vielen Dank für Ihren Besuch. Bitte checken Sie sich aus, wenn
                Sie unsere Räumlichkeiten verlassen.
            </div>
            <div onClick={() => checkOut()} className='btn'>
                Jetzt auschecken
                <img
                    style={{
                        marginLeft: "16px",
                    }}
                    src={login}
                />
            </div>
        </div>
    );
}
