import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import axios from "axios";
import login from "./login.svg";
import { useData } from "./utils/dataProvider";
import { useHistory } from "react-router-dom";
import Loader from "./Loader";
export default function BesuchForm() {
    const history = useHistory();
    const [sending, setSending] = useState(false);
    const [error, setError] = useState(null);
    const { data } = useData();

    const submitForm = (values) => {
        setError(null);
        if (!validateDates(values)) return;
        setSending(true);

        axios
            .post(
                `https://go.12-05.de/wp-json/1205checkin/v1/checkin`,
                {
                    datum: values.datum,
                    ankunft: values.ankunft,
                    abreise: values.abreise,
                    name: values.name,
                    unternehmen: values.unternehmen,
                    besuchsgrund: values.besuchsgrund,
                    ansprechpartner: values.ansprechpartner,
                },
                {
                    widthCredentials: true,
                    headers: {},
                }
            )
            .then((response) => {
                setSending(false);
                history.push("/checkout/" + response.data.data.token);
            })
            .catch((error) => {
                setSending(false);
                setError("Es ist ein Fehler aufgetreten.");
            });
    };

    function validateDates(values) {
        const date1 = new Date(values.vacation_start);
        const date2 = new Date(values.vacation_end);
        if (!date1 || !date2) {
            setError("Bitte geben Sie zwei Daten an.");
            return false;
        }
        if (date1.getTime() > date2.getTime()) {
            setError("Das Anfangsdatum muss nach dem Enddatum liegen.");
            return false;
        }
        return true;
    }
    const today = new Date();
    var day = today.getDate();
    var month = today.getMonth() + 1;
    var year = today.getFullYear();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    var todayFormatted = year + "-" + month + "-" + day;
    var nowForamtted =
        String(today.getHours()).padStart(2, "0") +
        ":" +
        String(today.getMinutes()).padStart(2, "0");
    const initialValues = {
        datum: todayFormatted,
        ankunft: nowForamtted,
        abreise: "",
        name: "",
        unternehmen: "",
        besuchsgrund: "",
        ansprechpartner: data?.ansprechpartner[0].name,
    };

    return (
        <div id='Form'>
            {sending && <Loader />}
            <div className='title'>Besucherliste</div>
            <Formik initialValues={initialValues} onSubmit={submitForm}>
                <Form>
                    <div className='field-group'>
                        <label>Datum</label>
                        <Field type='date' name='datum' required></Field>
                    </div>
                    <div className='time-input'>
                        <div className='field-group'>
                            <label>Ankunft</label>
                            <Field type='time' name='ankunft' required></Field>
                        </div>
                        <div className='field-group'>
                            <label>Abreise</label>
                            <Field type='time' name='abreise'></Field>
                        </div>
                    </div>
                    <div className='field-group'>
                        <label>Name, Vorname</label>
                        <Field type='text' name='name' required></Field>
                    </div>
                    <div className='field-group'>
                        <label>Unternehmen</label>
                        <Field type='text' name='unternehmen'></Field>
                    </div>
                    <div className='field-group'>
                        <label>Besuchsgrund</label>
                        <Field type='text' name='besuchsgrund' required></Field>
                    </div>
                    {data && (
                        <div className='field-group'>
                            <label>Ansprechpartner in 12-05</label>
                            <Field as='select' name='ansprechpartner'>
                                {data.ansprechpartner &&
                                    data.ansprechpartner.map((partner, idx) => {
                                        return (
                                            <option
                                                key={"option" + idx}
                                                value={partner.name}>
                                                {partner.name}
                                            </option>
                                        );
                                    })}
                            </Field>
                        </div>
                    )}

                    <button className='btn' type='submit'>
                        Einchecken
                        <img
                            style={{
                                marginLeft: "16px",
                                alignSelf: "baseline",
                            }}
                            src={login}
                        />
                    </button>
                </Form>
            </Formik>
        </div>
    );
}
